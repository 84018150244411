.signup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 480px;
}

.signup-bg {
  left: 0px;
  top: 0px;
  background: linear-gradient(
      270deg,
      #00003d 0%,
      rgba(0, 0, 61, 0) 3.92%,
      rgba(0, 0, 61, 0) 95.92%,
      #00003d 100%
    ),
    linear-gradient(0deg, rgba(0, 0, 61, 0.85), rgba(0, 0, 61, 0.85)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url(../../Images/SignupSection.jpg);
  background-blend-mode: normal, multiply, color, normal;
  background-size: cover;
  padding-top: 112px;
  padding-bottom: 112px;
}

.signup-header {
  h1 {
    margin-block-end: 0.35em !important;
  }

  p {
    font-family: DIN Next LT Pro;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
  }
}

.input-label {
  font-family: Share Tech Mono;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.signup-input {
  font-family: DIN Next LT Pro;
}

.signup-label {
  font-family: DIN Next LT Pro;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
}

.select-input {
  font-size: 16px;
  font-family: DIN Next LT Pro;
}
  
.css-1492t68 {
  color: rgba(0, 0, 61, 0.56) !important;
}

.css-vj8t7z {
  height: 48px !important;
}

.css-2o5izw {
  height: 48px !important;
}

.remove-padding {
  margin-top: 30px !important;
  padding: 0 !important;
}

.remove-padding-right {
  padding-right: 0 !important;
}

.submit-project-button {
  padding-top: 48px;
}

.project-list-wrapper {
    height: 480px;
    padding-top: 24px;
    overflow: scroll;
    overflow-x: hidden;
    margin-top: 8px;
}

.project-list-wrapper::-webkit-scrollbar {
    width: 8px;
}
 
/* Track */
.project-list-wrapper::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
 
/* Handle */
.project-list-wrapper::-webkit-scrollbar-thumb {
    opacity: 0.1;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding-left: 5px;
    background: #fff; 
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
}

@media screen and (max-width: 992px) {
    .project-signup-input {
        margin-top: 16px;
    }

    .signup-header {
      display: flex;
      justify-content: center;
    }
}