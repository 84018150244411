.student-splash-bg {
  left: 0px;
  top: 0px;
  background: linear-gradient(0deg, rgba(0, 0, 61, 0.85), rgba(0, 0, 61, 0.85)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url(../../Images/StudentSplashImage.jpeg);
  background-blend-mode: multiply, color, normal;
  background-size: cover;
  position: relative;
}

.dot-bg {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.splash-button-container {
  margin-top: 48px;
}

/*
  .information {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    cursor: default;
  }
  
  .information div {
    display: flex;
    align-items: center;
  }
  
  div p {
    display: inline;
    font-size: 18px;
    font-family: "Regular";
    color: #ffffff;
}

div .icon {
    color: #7c7ca5;
  }
  
  div .icon {
    padding-bottom: 4px;
    margin-right: 5px;
  }
  
  #splashImg {
    width: 100%;
  }
  
  @media screen and (max-width: 768px) {
    .splash {
      height: auto;
      margin-bottom: 80px;
    }
  
    #splashImg {
      display: none;
    }
  
    .information {
      display: block;
      margin-left: auto;
      margin-right: auto;
      // margin: 0;
    }
  
    .information div {
      display: flex;
      justify-content: left;
      // padding-left: 30px;
    }
  
    .Button {
      display: flex;
      justify-content: center;
    }
  }*/
