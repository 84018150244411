.footer {
    background-color: #0000FF; // height: 400px;
    margin-top: 120px;
    padding: 80px 0px;
}

.large-text {
    font-family: "Bold";
    font-size: 32px;
    line-height: 1.11;
    color: white;
    margin-bottom: 16px;
}

.description-text {
    font-family: "Regular";
    font-size: 24px;
    line-height: 1.41;
    color: white;
    margin-bottom: 32px;
}

.footer-icon {
    margin-bottom: -4px;
    margin-right: 16px;
}