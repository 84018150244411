@font-face {
    font-family: DIN Next LT Pro Bold;
    src: url(./Fonts/DINNextLTPro-Bold.otf);
}

@font-face {
    font-family: Heavy;
    src: url(./Fonts/DINNextLTPro-Heavy.otf);
}

@font-face {
    font-family: Light;
    src: url(./Fonts/DINNextLTPro-Light.otf);
}

@font-face {
    font-family: Medium;
    src: url(./Fonts/DINNextLTPro-Medium.otf);
}

@font-face {
    font-family: DIN Next LT Pro;
    src: url(./Fonts/DINNextLTPro-Regular.otf);
}

@font-face {
    font-family: Joschmi;
    src: url(./Fonts/Joschmi.otf);
}

body {
    margin: 0;
}

.center-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.add-blur {
    filter: blur(10px);
    -webkit-filter: blur(10px);
}

table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}