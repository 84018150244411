.h2 {
    font-family: DIN Next LT Pro Bold;
    font-size: 32px;
    color: white;
    text-align: center;
}

.h2-left {
    text-align: left;
}

.h2-right {
    text-align: right;
}