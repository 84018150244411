.title-wrapper {
    text-align: center;
    margin-bottom: 30px;
}

.title-wrapper p {
    font-family: 'Regular';
    font-size: 20px;
    color: #000000;
    display: inline-block;
    margin: 0px;
}
