.map-container {
    position: relative;
}

.flytoimage {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1000;
    box-shadow: 0 10px 44px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.5s;
}

.flytoimage:hover {
    transform: scale(1.1);
}

.flytoimage:active {
    transform: scale(1);
}