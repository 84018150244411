.info-card {
    margin-top: 30px;
}

.img-wrapper {
    width: 40px;
}

.icon-box {
    width: 100%;
}