.header-wrapper{
    margin-bottom: 32px;
}

.ixdbg{
    background: #00003D;
    padding-top: 36px;
    padding-bottom: 56px;
}

.ixd-description {
    color: white;
    font-family: "DIN Next LT Pro";
    text-align: center;
    font-size: 20px;
    line-height: 24px;
}

.ixd-description-small {
    color: white;
    font-family: "DIN Next LT Pro";
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    margin-top: 16px;
}
