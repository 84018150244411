.button {
  cursor: pointer;
  outline: none;
}

.button-primary {
  padding: 12px 20px;
  position: relative;
  background: #ffffff;
  border: 0px;
  border-radius: 4px;
  font-family: DIN Next LT Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 128, 0.72);
}

.button-primary:hover {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 128, 0.04),
      rgba(0, 0, 128, 0.04)
    ),
    #ffffff;
  color: rgba(0, 0, 128, 1);
}

.button-primary:disabled {
  background: #e1e1e1;
  color: #00003d;
  opacity: 0.32;
}

.button-primary:active {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 128, 0.04),
      rgba(0, 0, 128, 0.04)
    ),
    #ffffff;
  color: #000080;
}

.button-primary:focus {
  background: linear-gradient(0deg, rgba(0, 0, 61, 0.12), rgba(0, 0, 61, 0.12)),
    #ffffff;
  border: 1px solid #000080;
  box-sizing: border-box;
}

.button-secondary {
  padding: 12px 20px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: DIN Next LT Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 255, 0);
  color: #ffffff;
}

.button-secondary:hover {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  color: #000080;
}

.button-secondary:disabled {
  background: rgba(225, 225, 225, 0.24);
  border: 1px solid rgba(225, 225, 225, 0.24);
  box-sizing: border-box;
  border-radius: 4px;
  color: #e1e1e1;
  opacity: 0.24;
}

.button-secondary:focus {
  background: rgba(255, 255, 255, 0.24);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  color: #ffffff;
}

.button-secondary:active {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ffffff;
  color: #000080;
}

.button-ghost {
  padding: 8px 12px;
  background: rgba(0, 0, 255, 0);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Share Tech Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.56);
}

.button-ghost:hover {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  color: #000080;
}

.button-ghost:disabled {
  background: rgba(225, 225, 225, 0.24);
  border: 1px solid rgba(225, 225, 225, 0.24);
  box-sizing: border-box;
  border-radius: 4px;
  color: #e1e1e1;
  opacity: 0.24;
}

.button-ghost:focus {
  background: rgba(255, 255, 255, 0.24);
  border: 1px solid #ffffff;
  color: #ffffff;
}

.button-ghost:active {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ffffff;
  color: #000080;
}

.button-ghost-secondary {
  padding: 8px 12px;
  background: rgba(0, 0, 255, 0);
  opacity: 0.72;
  border: 1px solid #000080;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Share Tech Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000080;
}

.button-ghost-secondary:hover {
  background: #000080;
  box-sizing: border-box;
  border-radius: 4px;
  color: #e1e1e1;
}

.button-ghost-secondary:disabled {
  background: rgba(225, 225, 225, 0.24);
  border: 1px solid rgba(225, 225, 225, 0.24);
  box-sizing: border-box;
  border-radius: 4px;
  color: #e1e1e1;
  opacity: 0.24;
}

.button-ghost-secondary:focus {
  background: #0000c9;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.button-ghost-secondary:active {
  background: #0000c9;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.button-swhite {
  padding: 8px 10px;
  position: absolute;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  font-family: Share Tech Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000080;
  opacity: 0.72;
}

.button-swhite:hover {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 128, 0.04),
      rgba(0, 0, 128, 0.04)
    ),
    #ffffff;
  color: #000080;
}

.button-swhite:disabled {
  background: #e1e1e1;
  border-radius: 4px;
  color: #00003d;
  opacity: 0.32;
}

.button-swhite:focus {
  background: linear-gradient(0deg, rgba(0, 0, 61, 0.12), rgba(0, 0, 61, 0.12)),
    #ffffff;
  border: 1px solid #000080;
  color: #000080;
}

.button-swhite:active {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 128, 0.04),
      rgba(0, 0, 128, 0.04)
    ),
    #ffffff;
  color: #000080;
}
