.sign-up-card {
  border-radius: 4px;
  box-shadow: 0 10px 44px 0 rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-right: 5px;
  margin-bottom: 16px;
  position: relative;
  background: #ffffff;
}

.project-card-title {
  font-family: Joschmi;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.02em;
  font-feature-settings: "pnum" on, "lnum" on, "ss02" on, "liga" off;
  color: #00003d;
  padding-bottom: 4px;
  max-width: 75%;
}

.project-student {
  padding-left: 10px;
  font-family: Share Tech Mono;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(0, 0, 61, 0.56);
}

.project-student-icon {
  margin-top: 3px;
}
