.h1 {
  /*font-size: 56px;
    font-weight: 900;
    line-height: 0.94;
    color: #22224b;
    font-family: "Heavy";*/

  font-family: DIN Next LT Pro Bold;
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.h1-small {
  font-family: DIN Next LT Pro Bold;
  font-size: 45px;
  letter-spacing: -0.02em;
  color: #ffffff;
}
