.footer-splash-bg {
  left: 0px;
  top: 0px;
  background: url(../../Images/footer-splash.png);
  background-blend-mode: multiply, color, normal;
  background-size: cover;
  position: relative;
}

.dot-bg {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.paragraph {
  font-family: DIN Next LT Pro;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.splash-button-container {
  margin-top: 48px;
}
.splash-buttons {
  display: flex;
  justify-content: space-between;
}

.splash-company-container {
  margin-top: 118px;
}

.splash-company-paragraph {
  font-family: Share Tech Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 24px;
}
