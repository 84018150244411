.logos {
  display: inline-block;
}

.logo-img {
  height: 40px;
  margin: 0 0px;
  margin-right: 40px;
  margin-bottom: 48px;
  animation: all 1s ease-in-out;
}

.logo-img:hover {
  transform: scale(1.1);
}
