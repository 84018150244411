.header-link {
  font-family: DIN Next LT Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.6);
  flex: none;
  order: 0;
  align-self: center;

  &:hover {
    cursor: pointer;
    color: #ffffff;
  }
}

.header-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #ffffff;
  transition: width 0.3s;
}

.header-link:hover::after {
  width: 100%;
  //transition: width .3s;
}

.header-link-col {
  margin-top: 48px;
}
