.card {
    border-radius: 4px;
    font-family: "Regular";
    font-size: 20px;
    color: #22224b;
    box-shadow: 0 10px 44px 0 rgba(0, 0, 0, 0.1);
    padding: 24px;
}

.line {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.08);
}

.fee-text {
    color: rgba(29, 29, 100, 0.58);
    font-size: 16px;
    font-family: "Regular";
    margin-top: 32px;
    display: inline-block;
}

.price-text {
    float: right;
    font-size: 32px;
    color: #22224b;
    font-family: "Bold";
    margin-top: 24px;
}

.currency-text {
    float: right;
    color: #22224b;
    font-family: "Bold";
    font-size: 24px;
    margin-top: 30px;
    margin-left: 6px;
}

.input {
    display: block;
    width: calc(100% - 15px);
    height: 48px;
    padding: 0 0 0 15px;
    border-radius: 4px;
    font-size: 16px;
    border: solid 1px rgba(0, 0, 0, 0.2);
    font-family: DIN Next LT Pro;
}

.input:focus {
    border: solid 1px #0000FF;
    outline: none;
}

.input:hover {
    border: solid 1px #7c7ca5;
}

::placeholder {
    color: black;
    opacity: 0.32;
}

.input-valid {
    border: solid 1px rgba(0, 0, 0, 0.4);
}

.label {
    color: rgba(29, 29, 100, 0.58);
    font-size: 16px;
    font-family: "Regular";
    margin-top: 24px;
    margin-bottom: 16px;
}

.submit-button {
    width: 100%;
    height: 62px;
    background-color: #0000ff;
    border-radius: 60px;
    border: none;
    color: white;
    font-family: "Medium";
    font-size: 18px;
    margin-top: 24px;
    cursor: pointer;
    transition: 200ms;
}

.submit-button:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px 0 rgba(0, 0, 255, 0.25);
}

.submit-button:disabled {
    background-color: #bfbfbf;
    cursor: not-allowed;
    transform: scale(1.00);
    box-shadow: none;
}

.submit-button:focus {
    transform: scale(1.05);
    box-shadow: 0 0 10px 0 rgba(0, 0, 255, 0.25);
    outline: none;
}

.submit-button:active {
    transform: scale(1.00);
}

.icon {
    width: 24px;
    height: 24px;
}

.parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.spacing {
    margin-bottom: 32px;
}

.checkbox {
    background-color: white;
    border: rgba(0, 0, 0, 0.2);
}

.container {
    margin-top: 8px;
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: solid 2px rgba(0, 0, 0, 0.2);
    transition: all 0.5s;
}

/* On mouse-over, add a grey background color */

.container:hover input~.checkmark {
    border: solid 2px #0000ff;
}

.container:hover input~.checkmark {
    border: solid 2px #0000ff;
}

.container input:focus~.checkmark {
    border: solid 2px #0000ff;
}

/* When the checkbox is checked, add a blue background */

.container input:checked~.checkmark {
    border: solid 2px #0000ff;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #0000ff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.license-text {
    font-family: "Regular";
    color: #7c7ca5;
    font-size: 16px;
}

.info-text {
    font-family: "Regular";
    font-size: 20px;
    color: #22224b;
    margin-bottom: 8px;
}

/* Customize the label (the container) */

.radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 8px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: "Regular"; // color: rgba(0, 0, 0, 0.32);
    color: rgba(0, 0, 0, 0.56);
}

/* Hide the browser's default radio button */

.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */

.radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: white;
    border: rgba(0, 0, 0, 0.56) 2px solid;
}

.radio-container:hover {
    color: #22224b;
}

.radio-container-active {
    color: #22224b;
}

/* On mouse-over, add a grey background color */

.radio-container:hover input~.radio-checkmark {
    border: #0000FF solid 2px;
}

/* When the radio button is checked, add a blue background */

.radio-container input:checked~.radio-checkmark {
    background-color: white;
    border: #0000FF solid 2px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */

.radio-container input:checked~.radio-checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */

.radio-container .radio-checkmark:after {
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #0000ff;
}